@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@charset "UTF-8";
* {
  font-family: "Roboto", Arial, Helvetica, sans-serif; }

html {
  font-size: 12px; }

html, body {
  height: 100%;
  min-height: 100vh; }

.vh-100 {
  min-height: 720px;
  max-height: 720px; }

.full-page {
  margin: 0 auto; }
  .full-page .bg-red-orange {
    background: #ff5751; }
  .full-page .bg-soft-black {
    background: #343a40; }
  .full-page .bg-off-white {
    background: #f8f8f8; }
  .full-page .bg-pale-peach {
    background: #F9F5F1; }
  .full-page .bg-black {
    background: #1c1b20; }
  .full-page .primary-color-light {
    color: #58dcc1; }
  .full-page .primary-dark-color {
    color: #1c1b20; }
  .full-page .header-thin {
    font-weight: 300;
    color: #1c1b20; }
  .full-page .header-medium {
    font-weight: 500; }
  .full-page .nav-background-light {
    background: white;
    -webkit-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out; }
    .full-page .nav-background-light .active .nav-link {
      color: #1c1b20; }
      .full-page .nav-background-light .active .nav-link:hover {
        color: #1c1b20; }
    .full-page .nav-background-light .nav-link {
      color: #343a40; }
      .full-page .nav-background-light .nav-link:hover {
        color: rgba(28, 27, 32, 0.5); }
  .full-page .nav-background-dark {
    background: #1c1b20;
    -webkit-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out; }
    .full-page .nav-background-dark .navbar-toggler {
      border-color: rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.2); }
    .full-page .nav-background-dark .active .nav-link {
      color: white; }
      .full-page .nav-background-dark .active .nav-link:hover {
        color: white; }
    .full-page .nav-background-dark .nav-link {
      color: #f8f8f8; }
      .full-page .nav-background-dark .nav-link:hover {
        color: rgba(248, 248, 248, 0.8); }
  .full-page .white {
    color: white; }
  .full-page .primary-background {
    background: #ff5751; }
  .full-page .secondary-background {
    background: #F9F5F1; }
  .full-page .sub-section-header {
    opacity: 0;
    margin: 4% auto; }
  .full-page .all-cards img {
    max-width: 100%;
    flex-shrink: 0; }
  .full-page .all-cards .card {
    opacity: 0;
    font-weight: 500;
    border: none; }
    .full-page .all-cards .card .card-text {
      font-size: 1.5em;
      color: #343a40; }
  .full-page footer {
    background: #1c1b20;
    color: white; }
  .full-page .home .jumbo-head .headline-thin {
    font-size: 2rem; }
  .full-page .home .jumbo-head .headline-med {
    font-size: 4rem; }
  .full-page .home .jumbo-head .header-img {
    height: 350px; }
  .full-page .home .jumbo-head .header-text-container {
    top: 35%;
    left: 8%;
    opacity: 0;
    width: 85%; }
  .full-page .home .bg-white {
    background: white; }
  .full-page .home .hero-right-container #circle-to-bg {
    top: 60%;
    left: 50%;
    width: 1%;
    height: 1%;
    border-radius: 50%;
    background: #343a40;
    opacity: 0; }
  .full-page .home .hero-right-container .all-boxes {
    opacity: 0; }
  .full-page .home .hero-right-container .header-box {
    width: 40%;
    height: 40%;
    max-width: 400px;
    max-height: 400px; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    background: #ff9800;
    top: 30%;
    left: 20%;
    z-index: 1;
    border-radius: 10%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    top: 40%;
    left: 38%;
    width: 22%;
    height: 30%;
    z-index: 3;
    background: #F9F5F1;
    border-radius: 14% 0px 14% 0px; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    background: rgba(255, 87, 81, 0.9);
    top: 40%;
    left: 38%;
    z-index: 2;
    border-radius: 10%; }
  .full-page .home .section-second {
    padding-bottom: 5%; }
  .full-page .home .section-third #left-text-container {
    width: 80%;
    height: 50%;
    top: 35%;
    z-index: 1;
    right: 10%;
    opacity: 0;
    margin: 0 auto; }
    .full-page .home .section-third #left-text-container .section-three-small-col {
      color: #F9F5F1; }
      .full-page .home .section-third #left-text-container .section-three-small-col .small-col-line {
        font-weight: 700;
        line-height: 1; }
      .full-page .home .section-third #left-text-container .section-three-small-col #line-1 {
        font-size: 4.5rem; }
      .full-page .home .section-third #left-text-container .section-three-small-col #line-2 {
        font-size: 6.5rem; }
      .full-page .home .section-third #left-text-container .section-three-small-col #line-3 {
        font-size: 7.5rem; }
  .full-page .home .section-third #right-text-container {
    width: 75%;
    top: 30%;
    left: 0%;
    margin: 0 auto; }
    .full-page .home .section-third #right-text-container .section-three-large-col #large-line-1 {
      font-style: italic;
      font-weight: 500; }
    .full-page .home .section-third #right-text-container .section-three-large-col #large-line-2 {
      font-style: italic;
      font-weight: 300; }
    .full-page .home .section-third #right-text-container .section-three-large-col #large-line-3 {
      border: #1c1b20 solid 2px;
      width: 150px;
      height: 30px;
      text-align: center;
      text-transform: uppercase;
      padding: 5px;
      font-size: 1em;
      margin-top: 40px;
      display: inline-block;
      color: #1c1b20; }
      .full-page .home .section-third #right-text-container .section-three-large-col #large-line-3:hover {
        background: #ff9800;
        color: white;
        border: #ff9800 solid 2px; }
    .full-page .home .section-third #right-text-container .section-three-large-col .large-col-line {
      font-size: 3rem; }
  .full-page .about {
    overflow: hidden; }
    .full-page .about .hero-section {
      height: 530px; }
      .full-page .about .hero-section #top-to-bottom {
        background: #ff5751;
        top: 0;
        height: 0%; }
    .full-page .about .left-hero {
      background: #343a40; }
    .full-page .about #hero-text-left {
      color: #F9F5F1;
      top: 40%;
      opacity: 0;
      font-size: 5rem; }
    .full-page .about .right-hero {
      background: #F9F5F1;
      font-weight: 500; }
    .full-page .about .hero-text {
      width: 50%;
      margin: 0 auto; }
    .full-page .about #hero-text-right {
      opacity: 0;
      top: 35%; }
    .full-page .about .hero-text-right-top {
      color: #1c1b20;
      font-size: 3rem;
      font-weight: 400; }
    .full-page .about .hero-text-right-bottom {
      color: #F9F5F1;
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 3%; }
    .full-page .about .pdf-button {
      margin: 30px auto; }
      .full-page .about .pdf-button a .resume-button {
        height: 50px;
        width: 400px;
        border: #1c1b20 solid 2px;
        text-align: center;
        text-transform: uppercase;
        padding: 5px;
        font-size: 2rem;
        color: #1c1b20;
        margin: 0 auto; }
        .full-page .about .pdf-button a .resume-button:hover {
          color: white;
          background: #ff9800;
          border: #ff9800 solid 2px; }
      .full-page .about .pdf-button a:hover {
        text-decoration: none; }
    .full-page .about .about-me {
      margin: 10% auto; }
      .full-page .about .about-me #about-me-left {
        opacity: 0;
        left: -30px; }
      .full-page .about .about-me #about-me-right {
        opacity: 0;
        left: 30px; }
      .full-page .about .about-me #personal-img {
        border-radius: 5%; }
      .full-page .about .about-me #personal-text-container {
        font-size: 1.5rem; }
    .full-page .about .aspiration-section ul.aspiration-list {
      font-size: 1.7rem;
      color: #F9F5F1;
      width: 70%;
      margin: 0 auto;
      font-weight: 300;
      font-style: italic;
      line-height: 2;
      padding: 0% 5% 5%;
      list-style: none; }
      .full-page .about .aspiration-section ul.aspiration-list li::before {
        content: "• ";
        color: #1c1b20; }
  .full-page .work .hero-section {
    height: 530px; }
    .full-page .work .hero-section .hero-text {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 7rem;
      color: #1c1b20; }
      .full-page .work .hero-section .hero-text #work-hero-text {
        opacity: 0; }
      .full-page .work .hero-section .hero-text #scaling-period {
        opacity: 0;
        color: #f8f8f8;
        right: 1.5%; }
      .full-page .work .hero-section .hero-text .hero-text-container {
        top: 45%; }
  .full-page .work .project-section .card img {
    max-width: 100%;
    flex-shrink: 0; }
  .full-page .work .project-section .card .viewButton {
    background: #ff5751;
    border: none; }
  .full-page .work .client-card-container {
    margin-bottom: 5%; }
    .full-page .work .client-card-container .client-card {
      position: relative;
      height: 150px; }
      .full-page .work .client-card-container .client-card img {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 150px; }
  .full-page .contact .left-section #hero-text {
    opacity: 0;
    left: -30px; }
  .full-page .contact .left-section .left-text-container {
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 3.5rem;
    color: #1c1b20; }
  .full-page .contact .right-section .right-text-container {
    top: 50%;
    color: white; }
    .full-page .contact .right-section .right-text-container .all-details {
      width: 90%;
      margin: 0 auto; }
      .full-page .contact .right-section .right-text-container .all-details .half {
        margin-bottom: 5%; }
      .full-page .contact .right-section .right-text-container .all-details .details {
        width: 50%; }
        .full-page .contact .right-section .right-text-container .all-details .details .detail-title {
          font-weight: 300;
          font-size: 1rem; }
        .full-page .contact .right-section .right-text-container .all-details .details .detail-description {
          font-weight: 300;
          font-size: 1.3rem; }
          .full-page .contact .right-section .right-text-container .all-details .details .detail-description a {
            color: white; }
            .full-page .contact .right-section .right-text-container .all-details .details .detail-description a .fa-linkedin-square {
              font-size: 25px; }

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .full-page .home .jumbo-head .headline-med {
    font-size: 3rem; }
  .full-page .home .hero-right-container .header-box {
    width: 50%; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    left: 16%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    left: 34%;
    width: 32%; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    left: 34%; }
  .full-page .about #hero-text-left {
    font-size: 3.5rem; }
  .full-page .about .hero-text-right-top {
    font-size: 2.5rem; }
  .full-page .about .hero-text-right-bottom {
    font-size: 1.3rem; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .full-page .home .jumbo-head .headline-thin {
    font-size: 1.5rem; }
  .full-page .home .jumbo-head .headline-med {
    font-size: 2.6rem; }
  .full-page .home .jumbo-head .header-text-container {
    top: 35%;
    left: 8%;
    opacity: 0;
    width: 85%; }
  .full-page .home .hero-right-container .header-box {
    width: 50%;
    height: 35%; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    left: 17%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    left: 34%;
    width: 33%;
    height: 25%; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    left: 34%; }
  .full-page .home .section-third #left-text-container .section-three-small-col #line-1 {
    font-size: 3rem; }
  .full-page .home .section-third #left-text-container .section-three-small-col #line-2 {
    font-size: 5rem; }
  .full-page .home .section-third #left-text-container .section-three-small-col #line-3 {
    font-size: 6rem; }
  .full-page .work .hero-section .hero-text {
    font-size: 6rem; }
  .full-page .work .project-section .card {
    min-height: 320px; }
  .full-page .contact .left-section .left-text-container {
    font-size: 3rem; }
  .full-page .contact .right-section .right-text-container .all-details .details {
    width: 50%; }
    .full-page .contact .right-section .right-text-container .all-details .details .detail-description {
      font-size: 1rem; } }

@media only screen and (min-width: 1440px) {
  .full-page .home .jumbo-center {
    position: relative;
    width: 700px;
    margin: 0 auto;
    height: 100%; }
  .full-page .home .animation-container {
    position: relative;
    width: 700px;
    height: 100%;
    margin: 0 auto; }
  .full-page .home .section-third #left-text-container .section-three-small-col {
    width: 300px;
    margin: 0 auto; }
  .full-page .about #hero-text-left {
    max-width: 250px; }
  .full-page .about #hero-text-right {
    width: 70%;
    margin: 0 auto; }
  .full-page .about .aspiration-section ul.aspiration-list {
    width: 830px;
    padding: 0% 0% 5%; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .full-page .home .jumbo-head .headline-thin {
    font-size: 1.5rem; }
  .full-page .home .jumbo-head .headline-med {
    font-size: 2rem; }
  .full-page .home .jumbo-head .header-text-container {
    left: 20%;
    width: 70%; }
  .full-page .home .hero-right-container .header-box {
    width: 55%; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    left: 14%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    width: 37%;
    left: 32%; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    left: 32%; }
  .full-page .home .card-img-top {
    margin: 0 auto; }
  .full-page .home .section-third #left-text-container {
    top: 30%; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-1 {
      font-size: 2rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-2 {
      font-size: 4rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-3 {
      font-size: 5rem; }
  .full-page .home .section-third #right-text-container .section-three-large-col .large-col-line {
    font-size: 2rem; }
  .full-page .about .aspiration-section ul.aspiration-list {
    padding: 0% 0% 5%; } }

@media only screen and (max-width: 767px) {
  .full-page .home .jumbo-head .headline-thin {
    font-size: 2rem; }
  .full-page .home .jumbo-head .headline-med {
    font-size: 3rem; }
  .full-page .home .hero-right-container .header-box {
    width: 40%; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    left: 20%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    width: 22%;
    left: 38%; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    left: 38%; }
  .full-page .home .section-third #left-text-container {
    text-align: center;
    right: 6%;
    width: 50%; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-1 {
      font-size: 4rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-2 {
      font-size: 6rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-3 {
      font-size: 7rem; }
  .full-page .home .section-third #right-text-container .section-three-large-col .large-col-line {
    font-size: 3rem; }
  .full-page .about #hero-text-left {
    font-size: 4rem;
    text-align: center; }
  .full-page .about #hero-text-right {
    top: 25%; }
  .full-page .about .hero-text-right-top {
    font-size: 2.5rem; }
  .full-page .about .hero-text-right-bottom {
    font-size: 1.3rem; }
  .full-page .about .about-me #personal-img {
    width: 50%;
    margin: 0 auto;
    display: block; }
  .full-page .about .about-me #personal-text-container {
    font-size: 1.2rem;
    margin-top: 5%;
    padding: 0 10%; }
  .full-page .about .aspiration-section ul.aspiration-list {
    font-size: 1.3rem;
    margin-top: 5px; }
  .full-page .work .hero-section .hero-text {
    font-size: 5rem; }
    .full-page .work .hero-section .hero-text #scaling-period {
      right: 2.5%; }
  .full-page .contact .left-section .left-text-container {
    font-size: 2.3rem; }
  .full-page .contact .right-section .right-text-container .all-details .details .detail-title {
    font-size: 1.4rem; }
  .full-page .contact .right-section .right-text-container .all-details .details .detail-description {
    font-size: 1.3rem; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .vh-100 {
    min-height: 500px; }
  .full-page .all-cards .card .card-text {
    font-size: 1em; }
  .full-page .home .jumbo-head .headline-thin {
    font-size: 1.5rem; }
  .full-page .home .jumbo-head .headline-med {
    font-size: 2.3rem; }
  .full-page .home .hero-right-container .header-box {
    width: 40%; }
  .full-page .home .hero-right-container .animation-container #light-header-box {
    left: 20%; }
  .full-page .home .hero-right-container .animation-container #mid-header-box {
    width: 22%;
    left: 38%; }
  .full-page .home .hero-right-container .animation-container #dark-header-box {
    left: 38%; }
  .full-page .home .section-third #left-text-container {
    right: 6%;
    width: 50%; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-1 {
      font-size: 2rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-2 {
      font-size: 4rem; }
    .full-page .home .section-third #left-text-container .section-three-small-col #line-3 {
      font-size: 5rem; }
  .full-page .home .section-third #right-text-container .section-three-large-col .large-col-line {
    font-size: 2rem; }
  .full-page .about .hero-text {
    width: 85%; }
  .full-page .about #hero-text-right {
    top: 35%; }
  .full-page .about .hero-text-right-top {
    font-size: 2rem; }
  .full-page .about .hero-text-right-bottom {
    font-size: 1.3rem; }
  .full-page .about .pdf-button a .resume-button {
    width: 250px; }
  .full-page .about .aspiration-container .aspiration-section ul.aspiration-list {
    font-size: 1.3rem;
    margin-top: 5px; }
  .full-page .work .hero-section .hero-text {
    font-size: 3.7rem; }
    .full-page .work .hero-section .hero-text #scaling-period {
      right: 3%; }
  .full-page .contact .right-section .right-text-container .all-details .details .detail-title {
    font-size: .9rem; }
  .full-page .contact .right-section .right-text-container .all-details .details .detail-description {
    font-size: .8rem; } }

/* Custom, iPhone Retina */

