@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

//Variables
$font-family: "Roboto", Arial, Helvetica, sans-serif;
$font-weight-thin: 300;
$font-weight-reg: 400;
$font-weight-med: 500;
$font-weight-bold: 600;

$green: rgb(88, 220, 193);
$green-opac: rgba(88, 220, 193, 0.8);
$soft-black: #343a40;
$black: #1c1b20;
$pale-peach: #F9F5F1;
$orange: rgb(252, 133, 36);
$vivid-orange: rgb(255, 152, 0);
$white: white;
$dark-red: #a31e32;
$red: #FF1717;
$red-orange: rgb(255, 87, 81);
$red-orange-opac: rgba(255, 87, 81, 0.9);
$white: white;
$off-white: #f8f8f8;
$gold: rgb(253, 194, 45);
$ultra-blue: #1D2B92;
$beige: #ded5c4;

* {
    font-family: $font-family;
}

html {
    font-size: 12px;
}

html, body {
    height: 100%;
    min-height: 100vh;
}

.vh-100 {
    min-height: 720px;
    max-height: 720px;
}

.full-page {
    // max-width: 1440px;
    margin: 0 auto;

    .bg-red-orange {
        background: $red-orange;
    }

    .bg-soft-black {
        background: $soft-black;
    }

    .bg-off-white {
        background: $off-white;
    }

    .bg-pale-peach {
        background: $pale-peach;
    }

    .bg-black {
        background: $black;
    }

    .primary-color-light {
        color: $green;
    }

    .primary-dark-color {
        color: $black;
    }

    .header-thin {
        font-weight: $font-weight-thin;
        color: $black;
    }

    .header-medium {
        font-weight: $font-weight-med;
    }

    .nav-background-light {
        background: $white;
        transition: background-color 1s ease-out;

        .active .nav-link {
            &:hover {
                color: $black;
            }

            color: $black;
        }

        .nav-link {
            &:hover {
                color: rgba(28, 27, 32, 0.5);
            }

            color: $soft-black;
        }
    }

    .nav-background-dark {
        background: $black;
        transition: background-color 1s ease-out;

        .navbar-toggler {
            border-color: rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.2);
        }

        .active .nav-link {
            &:hover {
                color: $white;
            }

            color: $white;
        }

        .nav-link {
            &:hover {
                color: rgba(248, 248, 248, 0.8);
            }

            color: $off-white;
        }
    }

    .white {
        color: $white;
    }

    .primary-background {
        // background: $orange;
        // background: $green;
        background: $red-orange;
    }

    .secondary-background {
        // background:$off-white;
        // background: $soft-black;
        background: $pale-peach;
    }

    .sub-section-header {
        opacity: 0;
        margin: 4% auto;
    }

    .all-cards {
        // display: flex;
        img {
            max-width: 100%;
            flex-shrink: 0;
        }

        .card {
            opacity: 0;

            // max-width: 150px;
            // margin: 2%;
            font-weight: 500;
            border: none;

            .card-text {
                font-size: 1.5em;
                color: $soft-black;

                // text-shadow:  4px 4px 0px rgba(0,0,0,0.25);
           
            }
        }
    }

    footer {
        background: $black;
        color: $white;
    }

    .home {
        .jumbo-head {
            .headline-thin {
                font-size: 2rem;
            }

            .headline-med {
                font-size: 4rem;
            }

            .header-img {
                height: 350px;
            }

            .header-text-container {
                top: 35%;
                left: 8%;
                opacity: 0;
                width: 85%;
            }
        }

        .bg-white {
            background: $white;
        }

        .hero-right-container {
            #circle-to-bg {
                top: 60%;
                left: 50%;
                width: 1%;
                height: 1%;
                border-radius: 50%;
                background: $soft-black;
                opacity: 0;
            }

            .all-boxes {
                opacity: 0;
            }

            .header-box {
                width: 40%;
                height: 40%;

                // min-width: 290px;
                // min-height: 290px;
                max-width: 400px;
                max-height: 400px;
            }

            .animation-container {
                #light-header-box {
                    // background: $green;
                    background: $vivid-orange;

                    // background: $gold;
                    // background: $pale-peach;
                    // background: $green-opac;
                    top: 30%;
                    left: 20%;
                    z-index: 1;
                    border-radius: 10%;
                }

                #mid-header-box {
                    top: 40%;
                    left: 38%;
                    width: 22%;
                    height: 30%;
                    z-index: 3;

                    // max-width: 170px;

                    //max-width: 170px;

                    // background: $vivid-orange;
                    // background: $green-opac;
                    // background: $black;
                    // background: $gold;
                    background: $pale-peach;
                    border-radius: 14% 0px 14% 0px;
                }

                #dark-header-box {
                    background: $red-orange-opac;

                    // background: $red-orange;
                    top: 40%;
                    left: 38%;
                    z-index: 2;
                    border-radius: 10%;
                }
            }
        }

        .section-second {
            // height: 700px;
            padding-bottom: 5%;

            // background: #f8f8f8;
       
        }

        .section-third {
            #left-text-container {
                // background: $soft-black;
                width: 80%;
                height: 50%;
                top: 35%;
                z-index: 1;
                right: 10%;
                opacity: 0;
                margin: 0 auto;

                .section-three-small-col {
                    color: $pale-peach;

                    .small-col-line {
                        font-weight: 700;
                        line-height: 1;
                    }

                    #line-1 {
                        // font-size: 4rem;
                        font-size: 4.5rem;
                    }

                    #line-2 {
                        // font-size: 6rem;
                        // font-size: 8rem;
                        // font-size: 7rem;
                        font-size: 6.5rem;
                    }

                    #line-3 {
                        // font-size: 7rem;
                        // font-size: 10rem;
                        // font-size: 8rem;
                        font-size: 7.5rem;
                    }
                }
            }

            #right-text-container {
                width: 75%;
                top: 30%;
                left: 0%;
                margin: 0 auto;

                .section-three-large-col {
                    #large-line-1 {
                        // font-size: 3rem;
                        font-style: italic;
                        font-weight: 500;
                    }

                    #large-line-2 {
                        // font-size: 3rem;
                        font-style: italic;
                        font-weight: 300;
                    }

                    #large-line-3 {
                        border: $black solid 2px;
                        width: 150px;
                        height: 30px;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 5px;
                        font-size: 1em;
                        margin-top: 40px;
                        display: inline-block;
                        color: $black;

                        &:hover {
                            background: $vivid-orange;
                            color: $white;
                            border: $vivid-orange solid 2px;
                        }
                    }

                    .large-col-line {
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    .about {
        overflow: hidden;

        .hero-section {
            height: 530px;

            // height: 100vh;

            // #bottom-to-top{
            //     background: $soft-black;
            //     bottom: 0;
            //     height: 100%;
            // }
            #top-to-bottom {
                background: $red-orange;
                top: 0;
                height: 0%;
            }
        }

        .left-hero {
            // background: $off-white;
            //  background: $pale-peach;
            background: $soft-black;

            // background: #51FEAD;
       
        }

        #hero-text-left {
            color: $pale-peach;
            top: 40%;
            opacity: 0;

            // left: 40%;
            font-size: 5rem;
        }

        .right-hero {
            background: $pale-peach;

            // background: #FEAD51;
            font-weight: 500;
        }

        .hero-text {
            width: 50%;
            margin: 0 auto;
        }

        #hero-text-right {
            opacity: 0;
            top: 35%;
        }

        .hero-text-right-top {
            color: #1c1b20;

            // top: 35%;
            font-size: 3rem;
            font-weight: 400;
        }

        .hero-text-right-bottom {
            color: $pale-peach;

            // top: 40%;
            font-size: 1.5rem;
            font-weight: 300;
            margin-top: 3%;
        }

        .pdf-button {
            margin: 30px auto;

            a {
                .resume-button {
                    height: 50px;
                    width: 400px;
                    border: $black solid 2px;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 5px;
                    font-size: 2rem;
                    color: $black;
                    margin: 0 auto;

                    &:hover {
                        color: $white;
                        background: $vivid-orange;
                        border: $vivid-orange solid 2px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .about-me {
            margin: 10% auto;

            #about-me-left {
                opacity: 0;
                left: -30px;
            }

            #about-me-right {
                opacity: 0;
                left: 30px;
            }

            #personal-img {
                border-radius: 5%;
            }

            #personal-text-container {
                font-size: 1.5rem;

                // left: -70px;
                // color:$green;
           
            }
        }

        .aspiration-section {
            ul.aspiration-list {
                font-size: 1.7rem;
                color: $pale-peach;
                width: 70%;
                margin: 0 auto;
                font-weight: 300;
                font-style: italic;
                line-height: 2;
                padding: 0% 5% 5%;
                list-style: none;

                li::before {
                    content: "• ";
                    color: $black;
                }
            }
        }
    }

    .work {
        .hero-section {
            height: 530px;

            .hero-text {
                // top: 50%;
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 7rem;
                color: $black;

                #work-hero-text {
                    opacity: 0;
                }

                #scaling-period {
                    opacity: 0;
                    color: $off-white;
                    right: 1.5%;
                }

                .hero-text-container {
                    top: 45%;
                }
            }
        }

        .project-section {
            .card {
                // height: 350px;
                
                    img{
                        max-width: 100%;
                        flex-shrink: 0;
                    }
                    
                .viewButton {
                    background: $red-orange;
                    border: none;
                }
            }
        }

        .client-card-container {
            margin-bottom: 5%;

            .client-card {
                position: relative;
                height: 150px;

                img {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    width: 150px;
               
                }
            }
        }
    }

    .contact {
        .left-section {
            #hero-text {
                opacity: 0;
                left: -30px;
            }

            .left-text-container {
                top: 50%;
                width: 100%;
                text-align: center;
                font-size: 3.5rem;
                color: $black;
            }
        }

        .right-section {
            .right-text-container {
                top: 50%;
                color: $white;

                .all-details {
                    width: 90%;
                    margin: 0 auto;

                    .half {
                        margin-bottom: 5%;
                    }

                    .details {
                        width: 50%;

                        .detail-title {
                            font-weight: 300;
                            font-size: 1rem;
                        }

                        .detail-description {
                            font-weight: 300;
                            font-size: 1.3rem;

                            a {
                                color: $white;

                                .fa-linkedin-square {
                                    font-size: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
    .full-page {
        .home {
            .jumbo-head .headline-med {
                font-size: 3rem;
            }

            .hero-right-container {
                .header-box {
                    width: 50%;
                }

                .animation-container {
                    #light-header-box {
                        left: 16%;
                    }

                    #mid-header-box {
                        left: 34%;
                        width: 32%;
                    }

                    #dark-header-box {
                        left: 34%;
                    }
                }
            }
        }

        .about {
            #hero-text-left {
                font-size: 3.5rem;
            }

            .hero-text-right-top {
                font-size: 2.5rem;
            }

            .hero-text-right-bottom {
                font-size: 1.3rem;
            }
        }
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
    .full-page {
        .home {
            .jumbo-head {
                .headline-thin {
                    font-size: 1.5rem;
                }

                .headline-med {
                    font-size: 2.6rem;
                }

                // .header-img{
                //     height: 350px;
                // }
                .header-text-container {
                    top: 35%;
                    left: 8%;
                    opacity: 0;
                    width: 85%;
                }
            }

            .hero-right-container {
                .header-box {
                    width: 50%;
                    height: 35%;
                }

                .animation-container {
                    #light-header-box {
                        left: 17%;
                    }

                    #mid-header-box {
                        left: 34%;
                        width: 33%;
                        height: 25%;

                        // border-radius: 14% 0px 14% 0px;
                   
                    }

                    #dark-header-box {
                        left: 34%;
                    }
                }
            }

            .section-third {
                #left-text-container {
                    // background: $soft-black;

                    .section-three-small-col {
                        #line-1 {
                            font-size: 3rem;
                        }

                        #line-2 {
                            font-size: 5rem;
                        }

                        #line-3 {
                            font-size: 6rem;
                        }
                    }
                }
            }
        }

        .work {
            .hero-section {
                .hero-text {
                    font-size: 6rem;
                }
            }
        .project-section{
            .card{
                min-height: 320px;
            }
        }
        }

        .contact {
            .left-section {
                .left-text-container {
                    font-size: 3rem;
                }
            }

            .right-section {
                .right-text-container {
                    .all-details {
                        .details {
                            width: 50%;

                            .detail-description {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    // Xlarge Devices
    .full-page {
        .home {
            .jumbo-center {
                position: relative;
                width: 700px;
                margin: 0 auto;
                height: 100%;
            }

            .animation-container {
                position: relative;
                width: 700px;
                height: 100%;
                margin: 0 auto;
            }

            .section-third {
                #left-text-container {
                    .section-three-small-col {
                        width: 300px;
                        margin: 0 auto;
                    }
                }
            }
        }

        .about {
            #hero-text-left {
                max-width: 250px;
            }

            #hero-text-right {
                width: 70%;
                margin: 0 auto;
            }

            .aspiration-section {
                ul.aspiration-list{
                    width: 830px;
                    padding: 0% 0% 5%;
                }
            }
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    .full-page {
        .home {
            .jumbo-head {
                .headline-thin {
                    font-size: 1.5rem;
                }

                .headline-med {
                    font-size: 2rem;
                }

                .header-text-container {
                    left: 20%;
                    width: 70%;
                }
            }

            .hero-right-container {
                .header-box {
                    width: 55%;

                    // height: 30%;
               
                }

                .animation-container {
                    #light-header-box {
                        left: 14%;
                    }

                    #mid-header-box {
                        width: 37%;

                        // height: 20%;
                        left: 32%;
                    }

                    #dark-header-box {
                        left: 32%;
                    }
                }
            }

            .card-img-top {
                // width: 50%;
                margin: 0 auto;
            }

            .section-third {
                #left-text-container {
                    // background: $soft-black;
                    // text-align: center;
                    top: 30%;

                    .section-three-small-col {
                        #line-1 {
                            font-size: 2rem;
                        }

                        #line-2 {
                            font-size: 4rem;
                        }

                        #line-3 {
                            font-size: 5rem;
                        }
                    }
                }

                #right-text-container {
                    .section-three-large-col {
                        .large-col-line {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        .about {
            .aspiration-section {
                ul.aspiration-list {
                    padding: 0% 0% 5%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .full-page {
        .home {
            .jumbo-head {
                .headline-thin {
                    font-size: 2rem;
                }

                .headline-med {
                    font-size: 3rem;
                }
            }

            .hero-right-container {
                .header-box {
                    width: 40%;

                    // height: 40%;
               
                }

                .animation-container {
                    #light-header-box {
                        left: 20%;
                    }

                    #mid-header-box {
                        width: 22%;

                        // height: 30%;
                        left: 38%;
                    }

                    #dark-header-box {
                        left: 38%;
                    }
                }
            }

            .section-third {
                #left-text-container {
                    text-align: center;
                    right: 6%;
                    width: 50%;

                    .section-three-small-col {
                        #line-1 {
                            font-size: 4rem;
                        }

                        #line-2 {
                            font-size: 6rem;
                        }

                        #line-3 {
                            font-size: 7rem;
                        }
                    }
                }

                #right-text-container {
                    .section-three-large-col {
                        .large-col-line {
                            font-size: 3rem;
                        }
                    }
                }
            }
        }

        .about {
            #hero-text-left {
                font-size: 4rem;
                text-align: center;
            }

            #hero-text-right {
                top: 25%;
            }

            .hero-text-right-top {
                font-size: 2.5rem;
            }

            .hero-text-right-bottom {
                font-size: 1.3rem;
            }

            .about-me {
                #personal-img {
                    width: 50%;
                    margin: 0 auto;
                    display: block;
                }

                #personal-text-container {
                    font-size: 1.2rem;
                    margin-top: 5%;
                    padding: 0 10%;
                }
            }

            .aspiration-section {
                ul.aspiration-list {
                    font-size: 1.3rem;
                    margin-top: 5px;
                }
            }
        }

        .work {
            .hero-section {
                .hero-text {
                    font-size: 5rem;

                    #scaling-period {
                        right: 2.5%;
                    }
                }
            }
        }

        .contact {
            .left-section {
                .left-text-container {
                    font-size: 2.3rem;
                }
            }

            .right-section {
                .right-text-container {
                    .all-details {
                        .details {
                            .detail-title {
                                font-size: 1.4rem;
                            }

                            .detail-description {
                                font-size: 1.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
    .vh-100 {
        min-height: 500px;
    }

    .full-page {
        .all-cards {
            .card {
                .card-text {
                    font-size: 1em;
                }
            }
        }

        .home {
            .jumbo-head {
                .headline-thin {
                    font-size: 1.5rem;
                }

                .headline-med {
                    font-size: 2.3rem;
                }
            }

            .hero-right-container {
                .header-box {
                    width: 40%;

                    // height: 40%;
               
                }

                .animation-container {
                    #light-header-box {
                        left: 20%;
                    }

                    #mid-header-box {
                        width: 22%;

                        // height: 30%;
                        left: 38%;
                    }

                    #dark-header-box {
                        left: 38%;
                    }
                }
            }

            .section-third {
                #left-text-container {
                    right: 6%;
                    width: 50%;

                    .section-three-small-col {
                        #line-1 {
                            font-size: 2rem;
                        }

                        #line-2 {
                            font-size: 4rem;
                        }

                        #line-3 {
                            font-size: 5rem;
                        }
                    }
                }

                #right-text-container {
                    .section-three-large-col {
                        .large-col-line {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        .about {
            .hero-text {
                width: 85%;
            }

            #hero-text-right {
                top: 35%;
            }

            .hero-text-right-top {
                font-size: 2rem;
            }

            .hero-text-right-bottom {
                font-size: 1.3rem;
            }

            .pdf-button {
                a {
                    .resume-button {
                        width: 250px;
                    }
                }
            }

            .aspiration-container {
                // z-index: -1;

                .aspiration-section {
                    ul.aspiration-list {
                        font-size: 1.3rem;
                        margin-top: 5px;
                    }
                }
            }
        }

        .work {
            .hero-section {
                .hero-text {
                    font-size: 3.7rem;

                    #scaling-period {
                        right: 3%;
                    }
                }
            }
        }

        .contact {
            .right-section {
                .right-text-container {
                    .all-details {
                        .details {
                            .detail-title {
                                font-size: .9rem;
                            }

                            .detail-description {
                                font-size: .8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {}
